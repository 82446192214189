import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { ApointmentServiceService } from '../../../../../apointment/services/apointment-service.service';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder
} from '@angular/forms';
// import { Doctors } from '../../doctors.model';
import { DOCUMENT ,formatDate } from '@angular/common';
@Component({
  selector: 'app-status-dialog',
  templateUrl: './status-dialog.component.html',
  styleUrls: ['./status-dialog.component.sass']
})
export class StatusDialogComponent {
  action: string;
  dialogTitle: string;
  statusForm: FormGroup;
  status: string;
  appointmentId: string;
  // doctors: Doctors;
  constructor(
    public dialogRef: MatDialogRef<StatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public apointmentServiceService: ApointmentServiceService,
    private fb: FormBuilder,
    @Inject(DOCUMENT) private document: Document
  ) {
    // Set the defaults
    this.action = data.action;
    
    this.dialogTitle = 'Appointment status update';
      // this.doctors = new Doctors({});
    this.status = data.status
    this.appointmentId = data.appointmentId
    
    this.statusForm = this.createContactForm();
  }
  // formControl = new FormControl('', [
  //   Validators.required
  //   // Validators.email,
  // ]);
  // getErrorMessage() {
  //   return this.formControl.hasError('required')
  //     ? 'Required field'
  //     : '';
  // }
  nativeSelectFormControl = new FormControl(status, [
    Validators.required,
    Validators.pattern(status),
  ]);
  createContactForm(): FormGroup {
    return this.fb.group({
      status_edit: ['', Validators.required],
      AppId: ['', [Validators.required]]
      // id: [this.doctors.id],
      // img: [this.doctors.img],
      // name: [this.doctors.name],
      // email: [this.doctors.email],
      // date: [
      //   formatDate(this.doctors.date, 'yyyy-MM-dd', 'en'),
      //   [Validators.required]
      // ],
      // specialization: [this.doctors.specialization],
      // mobile: [this.doctors.mobile],
      // department: [this.doctors.department],
      // degree: [this.doctors.degree]
    });
  }
  submit() {
    // emppty stuff
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  public confirmAdd(): void {
    this.apointmentServiceService.UpdateStatusAppointment(this.appointmentId,this.statusForm.getRawValue()).subscribe();
    this.document.location.reload();
  }
}
